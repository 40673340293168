(function(doc, win) {
    var resetPage = function() {
      var deviceWidth = doc.documentElement.clientWidth || doc.body.clientWidth;
      var scale = deviceWidth / 750; //视口宽度 / 设计图尺寸, 1rem==50px
      // console.log(deviceWidth);
      if (deviceWidth >= 750) {
        doc.documentElement.style.fontSize = "100px";
      } else {
        doc.documentElement.style.fontSize = scale * 100 + "px";
      }
    };
    if (!doc.addEventListener) {
      return;
    }
    doc.addEventListener("DOMContentLoaded", resetPage, false);
    win.addEventListener("resize", resetPage, false);
  })(document, window);
  