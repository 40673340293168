import axios from "axios";
import qs from "qs";
import store from '@/store'
import { Dialog,Toast } from 'vant';

const successCode = [200, 0, 1, "200", "0", "1"];
const CODE_MESSAGE = {
    200: "服务器成功返回请求数据",
    201: "新建或修改数据成功",
    202: "一个请求已经进入后台排队(异步任务)",
    204: "删除数据成功",
    400: "发出信息有误",
    401: "用户没有权限(令牌、用户名、密码错误)",
    403: "用户得到授权，但是访问是被禁止的",
    404: "访问资源不存在",
    406: "请求格式不可得",
    410: "请求资源被永久删除，且不会被看到",
    500: "服务器发生错误",
    502: "网关错误",
    503: "服务不可用，服务器暂时过载或维护",
    504: "网关超时",
    20001: "Token过期，请重新登录"
};

const http = axios.create({
    timeout: 150000
});

// 处理请求返回值
const handleResponse = ({ config, data, status, statusText }) => {
    //处理code
    let code = data?.code ? data.code : status;
    successCode.includes(code) && (code = 200);
    //如果为200 则resolve
    if (code === 200) {
        return Promise.resolve(data);
    }
    else if (code == 20001) {
        Dialog.confirm({
            title: '登录过期',
            message: '您所登录的账号已过期，请重新登录',
            theme: 'round-button',
        }).then(() => {
            store.dispatch("removeUserTokenAction").then(res => {
                if (res.code === 200) {
                    window.location.href = '/'
                }
            })
        })
        .catch(() => {
        });
    }
    //异常处理
    const message = `${!data ? CODE_MESSAGE[code] : !data.message ? statusText : data.message}`;
    if(message != "openId未绑定，请登录"){
        Toast(message)
    }
    return Promise.reject({ code, message });
};

/**
 * 请求错误处理函数
 */
const err = error => {
    const { data } = error.response
    return Promise.reject(data)
}

http.interceptors.request.use(
    (config) => {
        // console.log(config,1111)
        const TOKEN = store.getters.token || sessionStorage.getItem('oldComm_token')
        if (TOKEN) {
            config.headers['token'] = TOKEN;
        }
        if (config.data && config.headers["Content-Type"] === "application/x-www-form-urlencoded;charset=UTF-8") {
            config.data = qs.stringify(config.data);
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response) => handleResponse(response),
    (error) => err(error)
);


export default http;
