export function imageIsExist(url) {
    return new Promise((resolve) => {
        var img = new Image();
        img.src = url;
        img.onload = function () {
            if (this.complete == true){
                resolve(true);
            }
        }
        img.onerror = function () {
            resolve(false);
        }
    })
}