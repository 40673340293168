import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import "./utils/common"
import 'amfe-flexible'
import "./style/common.css"
import store from "./store";

// 引入router
const app = createApp(App)
app.use(router)

// 路由拦截
import '@/router/interceptors'

//引入自定义指令
import RealImg from "@/directive/RealImg"
RealImg(app)

// 引入vant
import Vant from 'vant';
import 'vant/lib/index.css';
app.use(Vant)

import { Toast } from 'vant';
window._toast = Toast;

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// app.use(vConsole)

// 创建app
app.use(store)
app.mount('#app')