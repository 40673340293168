import router from '.'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
	showSpinner: false
})

const whiteList = ["/index","/investdetail","/newsdetail"];
// 全局路由拦截
router.beforeEach((to, from, next) => {
	const TOKEN = store.getters.token || sessionStorage.getItem('oldComm_token')
	
	NProgress.start()

	if (TOKEN) {
		if (to.path === '/login') {
			next({ path: '/index' })
		} else {
			next()
		}
	} else {
		if (whiteList.indexOf(to.path) !== -1) {
			next(); //指向(进入)to钩子--进入下一个页面
		}else{
			if (to.path === '/login') {
				next()
			} else {
				next(`/login?redirect=${to.fullPath}`)
			}
		}
	}
})

/** 通过路由钩子 动态渲染每次的title */
router.afterEach((to, from) => {
	NProgress.done()
	// document.title = to.meta?.name ? to.meta.name : '智慧创城'
})
