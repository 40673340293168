import { getLoginDB, loginoutDB, getUserInfoDB, loginwxOpenIdDB } from '@/interface'

const user = {
    state: {
        token: ''
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
    },
    actions: {
        // 判断是否需要登录
        getWxidLoginAction({ commit, dispatch },userState){
            return new Promise((resolve, reject) => {
                loginwxOpenIdDB(userState).then(res=>{
                    if(res.code == 200){
                        commit('SET_TOKEN', res.data)
                        sessionStorage.setItem('oldComm_token', res.data)
                        // 调用详情接口
                        dispatch("getUserinfoAction")
                        resolve(res)
                    }
                })
            });
        },
        // 用户登录
        getUserTokenAction({ commit, dispatch }, userState) {
            return new Promise((resolve, reject) => {
                getLoginDB(userState).then(async (res) => {
                    if (res.code === 200) {
                        commit('SET_TOKEN', res.data)
                        sessionStorage.setItem('oldComm_token', res.data)
                        // 调用详情接口
                        await dispatch("getUserinfoAction")
                        resolve(res)
                    }
                }).catch(error => {
                    resolve(error)
                })
            })
        },
        // 获取用户详情
        getUserinfoAction({ commit }) {
            return new Promise((resolve, reject) => {
                getUserInfoDB().then(res => {
                    if (res.code === 200) {
                        sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                    }
                    resolve(res)
                }).catch(error => {
                    resolve(error)
                })
            })
        },
        // 用户退出
        removeUserTokenAction({ commit, state }) {
            return new Promise((resolve, reject) => {
                loginoutDB().then(res => {
                    commit('SET_TOKEN', '')
                    // sessionStorage.removeItem('oldComm_wxid')
                    sessionStorage.removeItem('oldComm_token')
                    sessionStorage.removeItem('userInfo')
                    resolve({ message: '退出成功', code: 200 })
                }).catch(error => {
                    resolve(error)
                })
            })
        },
    }
}


export default user
