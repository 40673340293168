import { imageIsExist } from "@/utils/util"

export default function (app) {
    app.directive("real-img", {
        async mounted(el, binding) {
            let imgURL = binding.value;
            if (imgURL) {
                let exist = await imageIsExist(imgURL);
                if (exist) {
                    el.setAttribute('src', imgURL);
                }
            }
        }
    })
}