import http from "./interceptor";

export function getMethod(url, params) {
  return http({
    url: url,
    method: "get",
    params: params,
  });
}

export function postMethod(url, params) {
  return http({
    headers: {
      "Content-Type": "application/json"
    },
    url: url,
    method: "post",
    data: params,
  });
}

export function putMethod(url, params) {
  return http({
    url: url,
    method: "put",
    data: params,
  });
}

export function deleteMethod(url, params) {
  return http({
    url: url,
    method: "delete",
    params: params,
  });
}

export function imageUploadMethod(url, params) {
  return http({
    headers: { "Content-Type": "multipart/form-data" },
    url: url,
    method: "post",
    data: params,
  });
}

export function postFormMethod(url, params) {
  return http({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    },
    url: url,
    method: "post",
    data: params,
  });
}
