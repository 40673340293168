export default [
	{
		path: "/login",
		name: "login",
		component: () => import( /* webpackChunkName: 'login'*/ "@/views/login.vue")
	},
	{
		path: "/",
		name: "default",
		redirect: "/login"
	},
	{
		path: '/index',
		name: 'index',
		component: ()=>import('../views/index.vue')//首页
	},
	{
		path: '/investdetail',
		name: 'investdetail',
		component: ()=>import('../views/InvestDetail.vue')//调查详情
	},
	{
		path: '/newsdetail',
		name: 'newsdetail',
		component: ()=>import('../views/NewsDetail.vue')//新闻详情
	},
	{
		path: '/work',
		name: 'work',
		component: ()=>import('../views/work.vue')//工作台
	},
	
	{
		path: '/GongShiDetail',
		name: 'GongShiDetail',
		component: ()=>import('../views/GongShiDetail.vue')//公示详情
	},
	{
		path: '/CommunityDetail',
		name: 'CommunityDetail',
		component: ()=>import('../views/CommunityDetail.vue')//小区详情
	},
	{
		path: '/my',
		name: 'my',
		component: ()=>import('../views/my.vue')//我的
	},
	{
		path: '/jinkuang',
		name: 'jinkuang',
		component: ()=>import('../views/jinkuang/jinkuang.vue'), //近况上报
	},
	{
		path: '/JinKuangUpload',
		name: 'JinKuangUpload',
		component: ()=>import('../views/jinkuang/JinKuangUpload.vue') //近况上传
	},
	{
		path: '/qiandao',
		name: 'qiandao',
		component: ()=>import('../views/qiandao.vue')//考勤打卡
	},
];
