const {
	BASEURL
} = window.globalSetting;

export default {
	/**登录 */
	loginwxOpenId: `${BASEURL}/applet/system/loginxOpenId`,//根据wxid登录
	login: `${BASEURL}/applet/system/login`, //登录接口
	loginout: `${BASEURL}/applet/system/logout`, //登出接口
	getUserInfo: `${BASEURL}/applet/system/getUserInfo`, //获取用户角色接口
	wxConfig: `${BASEURL}/applet/config.do`,// 微信配置config.do
	upFile: `${BASEURL}/applet/file/currencyUploadImg`,  // 文件上传

	getStatData: `${BASEURL}/applet/home/count`,// 首页统计数据

	getNewsList: `${BASEURL}/applet/new/list/0`,// 新闻动态列表
	getNewsDetail: `${BASEURL}/applet/new/info/0`,// 新闻动态详情
	getgsList: `${BASEURL}/applet/new/list/1`,// 公示信息列表
	getgsDetail: `${BASEURL}/applet/new/info/1`,// 公示信息详情
	
	saveSign: `${BASEURL}/applet/work/in`,// 考勤打卡

	/**小区 */
	getCommunityList: `${BASEURL}/applet/residential/list`,// 小区列表
	getCommunityDetail: `${BASEURL}/applet/residential/info`,// 小区详情

	/**上报 */
	getReportInfo: `${BASEURL}/applet/work/report/info`,// 上报详情
	saveReport: `${BASEURL}/applet/work/report`,// 添加上报
	getReformSpeed: `${BASEURL}/applet/work/getReformSpeed`,// 查询上报进度

	// 民意调查
	getQuesList: `${BASEURL}/applet/ques/getList`,// 列表
	getQuesSubject: `${BASEURL}/applet/ques/getQuesSubject`,// 详情
	saveQuesResult: `${BASEURL}/applet/ques/saveResult`,// 提交调查
	getQuesState: `${BASEURL}/applet/ques/getState`, //判断是否填过民意调查

};