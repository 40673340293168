import { getMethod, postFormMethod, postMethod, imageUploadMethod } from "@/http";
import api from './api'

export const loginwxOpenIdDB = (params) => postFormMethod(api.loginwxOpenId, params);//登录接口
export const getLoginDB = (params) => postMethod(api.login, params);//登录接口
export const loginoutDB = () => postMethod(api.loginout);//登出接口
export const getUserInfoDB = () => getMethod(api.getUserInfo);//获取用户详情接口
export const wxConfigDB = (params) => postFormMethod(api.wxConfig, params);// 微信配置config.do
export const upFileDB = (params) => imageUploadMethod(api.upFile, params); // 文件上传

export const getStatDataDB = () => getMethod(api.getStatData); // 首页统计数据

export const getNewsListDB = () => getMethod(api.getNewsList);  // 新闻动态列表
export const getNewsDetailDB = (params) => getMethod(api.getNewsDetail, params);   // 新闻动态详情
export const getgsListDB = () => getMethod(api.getgsList);   // 公示信息列表
export const getgsDetailDB = (params) => getMethod(api.getgsDetail, params);   // 公示信息详情

export const saveSignDB = (params) => postMethod(api.saveSign, params);  //考勤打卡

export const getCommunityListDB = () => getMethod(api.getCommunityList);  // 小区列表
export const getCommunityDetailDB = (params) => getMethod(api.getCommunityDetail, params);  // 小区详情

export const getReportInfoDB = (params) => getMethod(api.getReportInfo, params);  // 上报详情
export const saveReportDB = (params) => postMethod(api.saveReport, params);  // 添加上报
export const getReformSpeedDB = (params) => getMethod(api.getReformSpeed, params);  // 改造进度

export const getQuesListDB = (params) => getMethod(api.getQuesList, params);  // 民意调查列表
export const getQuesSubjectDB = (params) => getMethod(api.getQuesSubject, params);  // 获取题目
export const saveQuesResultDB = (params) => postMethod(api.saveQuesResult, params);  // 提交调查
export const getQuesStateDB = (params) => getMethod(api.getQuesState, params);  // 判断是否填过民意调查